@import '../../defaults-page.scss';


.articleItem {
  display: flex;
  flex-wrap: wrap;
  margin-left: 3px;

  .article {
    min-width: 200px;

    a {
      color: darkgreen;
      font: {
        weight: bold;
        style: normal;
        size: 10px;
      }
    }
    a:hover {
      color: gray;
      font: {
        weight: bold;
        style: normal;
        size: 10px;
      }
      text-decoration: none;
    }
  }
}


.category {
    background-color: $background-highlight-color;
    color: $highlight-color;
    text: {
      align: right;
      transform: uppercase;
    }
    font: {
      weight: bold;
      size: 15px;
    }
    border: {
      bottom: {
        color: black;
        style: solid;
        width: 1px;
      }
    }
}
