.page-title {
    font-weight: bold;
    font-size: 25px;
    background: #7FBD7A;
}

.gallery-text {}

.gallery-pictures {
    display: flex;
    flex-wrap: wrap;
}

.gallery-pictures .picture {}