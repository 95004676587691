@import '../../defaults-page.scss';

.text {
    height: 100%;
}

.article {
    display: flex;
    height: 100%;
    flex-direction: column;

    margin: {
        left: 3px;
        right: 3px;
    }
}

.footnote {
    border: {
        style: solid none none none;
        width: 1px;
        color: gray;
    }

    position: relative;
    bottom: 0px;
    text-align: right;

    font: {
        size: 6pt;
        weight: bold;
    }

    color: gray;

}