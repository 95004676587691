.menu {
    border: 0px;
    margin: 0px;
    padding: 0px;
    width: 110px;
}

.menu_item {
    margin: 1px;
}

img {
    border: 0px;
    margin: 0px;
    padding: 0px;
}
