@import "../../defaults-page.scss";
$margin: 2px;

.row {
    display: table-row;

    @mixin background-strike {
        background: {
            image: url("/assets/img/back_kal.jpg");
            origin: content-box;
            repeat: repeat-x;
        }
    }

    .link {
        display: table-cell;
        margin: $margin;
        min-width: 26px;
        div {
            div {
                position: relative;
                top: 7px;
                height: 23px;
                @include background-strike();
            }
        }
    }

    .gallery {
        display: table-cell;
        margin: $margin;
        min-width: 26px;
        div {
            div {
                position: relative;
                top: 3px;
                height: 20px;
                
                @include background-strike();

            }
        }
    }

    .dates {
        display: table-cell;
        white-space: nowrap;
        margin: $margin;
        min-width: 220px;
        @include background-strike();

        .remove_background {
            background: {
                image: none;
                color: $background-default-color;
            }
        }
    }

    .info {
        padding-left: 5px;
        display: table-cell;
        width: 100%;
        margin: $margin;
    }

    .actions {
        display: table-cell;
        margin: $margin;
    }

    .pig {
        display: block;
    }
}