@import '../../defaults-page.scss';

.detail {
    display: block;
    width: 100%;
    height: 100%;

    border: {
        width: 1px;
        style: dotted;
        color: darkgreen;
    }
}

.page_title {
    font-weight: bold;
    font-size: 32px;
    color: $highlight-color;
    background: $background-highlight-color;
    padding: 4px;
    margin: 0px 0px 10px 0px;
    height: 56px;
    vertical-align: middle;
    display: flex;

    .left_side {
        flex: 1;
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .right_side {
        width: 96px;
        text-align: right;
        padding: 0px;
    }
}
