@import '../../defaults-page.scss';

.news {
  display: flex;

  .news-date {
      margin: 5px 5px 5px 10px;
  }

  .news-text {
      margin-top: 5px;
      flex: 100%;
  }
}
