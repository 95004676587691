@import '../../defaults-page.scss';

$margin: 5px;

.calendar {
    display: table;
    .header {
        display: table-row;

        .header_link {
            display: table-cell;
            width: 20px;
            margin: $margin;
        }

        .header_gallery {
            display: table-cell;
            width: 20px;
            margin: $margin;
        }

        .header_dates {
            display: table-cell;
            font-weight: bold;
            text-align: left;
            margin: $margin;
        }

        .header_info {
            display: table-cell;
            padding-left: 5px;
            font-weight: bold;
            text-align: left;
            width: 100%;
            margin: $margin;
        }

        .header_actions {
            display: table-cell;
            font-weight: bold;
            text-align: left;
            margin: $margin;
        }
    }
}