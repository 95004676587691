@import 'defaults.scss';

.page-title {
  font-weight: bold;
  font-size: 25px;
  color: $highlight-color;
  background: $background-highlight-color;
  padding: 3px;
  height: 54px;
  vertical-align: middle;
}

.page_title {
  font-weight: bold;
  font-size: 25px;
  color: $highlight-color;
  background: $background-highlight-color;
  padding: 3px;
  height: 54px;
  vertical-align: middle;
}

.admin-panel {
  button {
    margin: {
      left: 5px;
    }
  }
}


.detail {
  display: block;
  width: 100%;
  height: 100%;

  border: {
    width: 1px;
    style: dotted;
    color: darkgreen;
  }
}
