@import '../../defaults-page.scss';


.link_item {
    display: flex;
    flex-wrap: wrap;
    margin-left: 3px;

    .link {
        min-width: 200px;

        a {
            color: darkgreen;

            font: {
                weight: bold;
                style: normal;
                size: 10px;
            }
        }

        a:hover {
            color: gray;

            font: {
                weight: bold;
                style: normal;
                size: 10px;
            }

            text-decoration: none;
        }
    }

    .text {
        flex: 0 60%;

        :first-child {
            display: inline;
        }
    }
}



.category {
    background-color: $background-highlight-color;
    color: $highlight-color;

    text: {
        align: right;
        transform: uppercase;
    }

    font: {
        weight: bold;
        size: 15px;
    }

    border: {
        bottom: {
            color: black;
            style: solid;
            width: 1px;
        }
    }
}