.page-title {
    font-weight: bold;
    font-size: 25px;
    background: #7FBD7A;
}

.image {
    text-align: center;
}

.image>img {
    max-width: 80%;
}