@import '../../defaults-page.scss';

.page-title {
    display: flex;

    .gallery-paging-and-title {
        margin: 0;
        width: 760px;
        left: 0;
    }

    .gallery-picker {
        padding: 0;
        margin: 0;
        width: 96px;
    }
}

.gallery-picker-list {
    mdc-text-field {
        width: 305px;
        margin: 3px 16px 3px 16px;
        text-align: center;
    }

    a {
        color: darkgreen;
    }

    a:hover {
        color: gray;
        text-decoration: none;
    }

}

.galleries {
    display: flex;
    flex-wrap: wrap;

    .gallery {
        margin: 15px;
        flex: 0 45%;

        border: {
            style: solid;
            color: darkgreen;
            width: 1px;
        }

        .thumb {
            float: left;
            max-width: 122px;
        }

        .gallery-title {
            background: #C8FFC8;
            display: block;
            font-size: 20px;
            text-indent: 5px;
            padding: 5px;
        }
    }
}