@import "./defaults.scss";

$width: 990px;

body {
  font: {
    family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    size: 10pt;
  }

  background-color: $background-default-color;
  margin:auto;
  padding: 0pt;
}

.top-level {
  border: 1px;
  width: $width;

  border: {
    style: solid;
    color: Darkgreen;
  }

  margin: 0 auto;
}

.content {
  display: flex;
}

#rakosnicek-icon {
  text-align: center;
  width: 110px;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

#menu {
  margin: 0px 0px 5px 0px;
}

#detail-progress {
  padding: 0;
  flex: 100%;
  margin: 15px 5px 5px 5px;

  border: {
    width: 0;
    style: none;
  }
}

#progress {
  margin: 0;
  padding: 0;
  height: 4px;
  display: block;

  border: {
    width: 0;
    style: none;
  }
}

.main-cite {
  text-align: right;

  border: {
    style: dotted none none none;
    width: 1px;
    color: darkgreen;
  }

  margin: {
    left: 1%;
    right: 1%;
  }

  font: {
    size: 6pt;
    style: italic;
  }
}

.main-cite .green {
  color: darkgreen;
}

.main-version {
  text-align: right;

  font: {
    size: 4pt;
    style: italic;
  }

  width: $width;
  margin: 0 auto;
}
